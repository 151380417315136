import CONFIG from '~/config'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import { NODE_ENVS } from '~/constants'
import {
  toCamel,
  camelToSnakeCaseObj,
  getUserToken,
  getImpersonationToken,
  getAnonymousId
} from '~/utils'

import {
  USER_TAG_TYPE,
  USER_SUGGESTED_BILLS_TAG_TYPE,
  USER_LINKED_BILLS_TAG_TYPE,
  REWARDS_TRANSACTIONS_TAG_TYPE,
  NOTIFICATIONS_TAG_TYPE,
  CREDIT_SCORE_PROJECTIONS_TAG_TYPE,
  PAYMENTS_TAG_TYPE,
  SUBSCRIPTION_PLAN_BILL_TAG_TYPE,
  USER_AGREEMENTS_TAG_TYPE,
  INACTIVE_BANK_ACCOUNTS,
  USER_CLOSING_INFO_TAG_TYPE,
  GOALS_TAG_TYPE,
  QUESTIONNAIRE_TAG_TYPE,
  USER_DOCUMENTS_TAG_TYPE,
} from './constants'
import getUserEndpoints from './user'
import getSubscriptionEndpoints from './subscription'
import getAccountEndpoints from './account'
import getBankAccountEndpoints from './bankAccount'
import getBillEndpoints from './bill'
import getBillerEndpoints from './biller'
import getNotificationEndpoints from './notification'
import getGoalEndpoints from './goal'
import getRewardsEndpoints from './rewards'
import getSpendLimitEndpoints from './spendLimit'
import getCreditEndpoints from './credit'
import getPaymentEndpoints from './payment'
import getPayableEndpoints from './payable'
import getSweepstakesEndpoints from './sweepstakes'
import getUserAgreementEndpoints from './userAgreeements'
import getDebtReliefEndpoints from './debtRelief'
import getEnrollmentEndpoints from './enrollment'
import getQuestionnaireEndpoints from './questionnaire'
import getSaaSEndpoints from './saas'

const baseQuery = fetchBaseQuery({
  baseUrl: CONFIG.SERVER_URL,
  prepareHeaders: headers => {
    const token = getImpersonationToken() || getUserToken()

    // TODO: when signing up or logging in, check if token is ready to be used right away
    if (token) {
      headers.set('authorization', `Bearer ${token}`)
    }

    // Google OAuth for http and localhost
    if (CONFIG.ENVIRONMENT === NODE_ENVS.DEVELOPMENT) {
      headers.set('Referrer-Policy', 'no-referrer-when-downgrade')
    }

    headers.set('X-Anonymous-Id', getAnonymousId())

    // we need to delete this header in order for the browser to set it correctly when sending files
    if (headers.get('Content-Type') === 'multipart/form-data') {
      headers.delete('Content-Type')
    }

    // pass fingerprint visitor id so backend can get device data
    const visitorId = localStorage.getItem(CONFIG.FINGERPRINT_VISITOR_ID_KEY) || ''
    if (visitorId) {
      headers.set('X-Visitor-Id', visitorId)
    }

    return headers
  },
})

const api = createApi({
  reducerPath: 'api',
  baseQuery: async (args, apiEntity, extraOptions) => {
    const parsedArgs = typeof args === 'object' && args !== null ? {
      ...args,
      ...(args.params ? { params: camelToSnakeCaseObj(args.params) } : {}),
      ...(args.body ? {
        body: args.headers?.['Content-Type'] === 'multipart/form-data' ? args.body : camelToSnakeCaseObj(args.body),
      } : {}),
    } : args

    const result = await baseQuery(parsedArgs, apiEntity, extraOptions)

    return result.error ? { ...result, error: toCamel(result.error) } : { ...result, data: toCamel(result.data) }
  },
  tagTypes: [
    USER_TAG_TYPE,
    USER_SUGGESTED_BILLS_TAG_TYPE,
    USER_LINKED_BILLS_TAG_TYPE,
    REWARDS_TRANSACTIONS_TAG_TYPE,
    NOTIFICATIONS_TAG_TYPE,
    CREDIT_SCORE_PROJECTIONS_TAG_TYPE,
    PAYMENTS_TAG_TYPE,
    SUBSCRIPTION_PLAN_BILL_TAG_TYPE,
    USER_AGREEMENTS_TAG_TYPE,
    INACTIVE_BANK_ACCOUNTS,
    USER_CLOSING_INFO_TAG_TYPE,
    GOALS_TAG_TYPE,
    QUESTIONNAIRE_TAG_TYPE,
    USER_DOCUMENTS_TAG_TYPE,
  ],
  endpoints: builder => ({
    ...getUserEndpoints(builder),
    ...getSubscriptionEndpoints(builder),
    ...getAccountEndpoints(builder),
    ...getBankAccountEndpoints(builder),
    ...getBillEndpoints(builder),
    ...getBillerEndpoints(builder),
    ...getNotificationEndpoints(builder),
    ...getPaymentEndpoints(builder),
    ...getPayableEndpoints(builder),
    ...getGoalEndpoints(builder),
    ...getRewardsEndpoints(builder),
    ...getSpendLimitEndpoints(builder),
    ...getCreditEndpoints(builder),
    ...getSweepstakesEndpoints(builder),
    ...getUserAgreementEndpoints(builder),
    ...getDebtReliefEndpoints(builder),
    ...getEnrollmentEndpoints(builder),
    ...getQuestionnaireEndpoints(builder),
    ...getSaaSEndpoints(builder),
  }),
})

export const {
  // user
  useRegisterUserMutation,
  usePostHeardFromMutation,
  useLoginUserMutation,
  useLoginUserByTokenMutation,
  useVerifyUserMutation,
  useResendVerificationMutation,
  useSignInWithGoogleMutation,
  useSignUpWithGoogleMutation,
  useConnectWithGoogleMutation,
  useSignInWithAppleMutation,
  useSignUpWithAppleMutation,
  useConnectWithAppleMutation,
  useImpersonateUserMutation,
  useFetchUserQuery,
  useLazyFetchUserQuery,
  useFetchUserContextQuery,
  useLazyFetchUserContextQuery,
  useUpdateUserMutation,
  useUpdateUserSettingsMutation,
  useUpdatePasswordMutation,
  useUploadAvatarMutation,
  useSendVerificationEmailMutation,
  useSendPhonePinCodeMutation,
  useVerifyPhonePinCodeMutation,
  useConfirmIdentityMutation,
  useConfirmAccountSetupMutation,
  useRequestPasswordMutation,
  useResetPasswordMutation,
  useVerifyEmailMutation,
  useConfirmEmailMutation,
  useLazyResendEmailConfirmationQuery,
  useUploadDocumentMutation,
  useDeleteDocumentMutation,
  useFetchUserAgreementsQuery,
  useFetchDocumentRequestQuery,
  useFetchHighnoteDocumentUploadTokenQuery,
  useCompleteHighnoteDocumentUploadMutation,
  // subscription
  useGetSubscriptionPlansQuery,
  usePostSubscriptionPlanMutation,
  useGetSubscriptionPlanBillsQuery,
  // account
  usePauseAccountMutation,
  useUnpauseAccountMutation,
  useReopenAccountMutation,
  useCloseAccountMutation,
  useGetClosingInfoQuery,
  // bank
  useLinkBankAccountMutation,
  useGetLinkTokenQuery,
  useRelinkBankAccountMutation,
  useFetchInactiveBankAccountsQuery,
  // bill
  useGetLinkedBillsQuery,
  useLazyGetLinkedBillsQuery,
  useLazyGetSuggestedBillsQuery,
  useGetSuggestedBillsQuery,
  useDismissSuggestedBillMutation,
  useUnlinkBillMutation,
  useGetBillQuery,
  useCreateBillMutation,
  useConfirmBillMutation,
  useGetBillPaymentOptionsQuery,
  useMigrateBillPayCardMutation,
  useGetCreditCardClientTokenQuery,
  // biller
  useGetBillCategoriesQuery,
  useGetBillersQuery,
  useGetBillerQuery,
  // notifications
  useGetNotificationsQuery,
  useReadAllNotificationsMutation,
  useLazyGetNotificationsQuery,
  // goals
  useGetUserGoalsQuery,
  usePostGoalsMutation,
  useUpdateGoalMutation,
  useDeleteGoalMutation,
  useUploadGoalAvatarMutation,
  // rewards
  useFetchCreditEntriesQuery,
  useFetchRewardsQuery,
  useRedeemRewardPointsMutation,
  useGetRewardsTransactionsQuery,
  useRedeemPromoCodeMutation,
  // spend limit
  useGetSpendLimitReportQuery,
  useGetSpendLimitHistoryQuery,
  useLazyGetSpendLimitHistoryQuery,
  useGetSpendLimitReportingHistoryQuery,
  useLazyGetSpendLimitReportingHistoryQuery,
  // credit tools
  useGetUserTokenQuery,
  useGetCreditReportQuery,
  // credit scores
  useGetCreditScoreProjectionsQuery,
  // credit report
  useGetLatestCreditReportQuery,
  useGetLatestStellarTradelineQuery,
  // payments
  useGetPaymentsQuery,
  useLazyGetPaymentsQuery,
  // payables
  useRepayPayableMutation,
  // sweepstakes
  useEnterSweepstakesMutation,
  // user agreement
  useGetUserAgreementQuery,
  usePostUserAgreementMutation,
  // debt relief
  useFetchDebtReliefUserQuery,
  useVerifyDebtReliefUserSSNMutation,
  useRegisterDebtReliefUserMutation,
  // enrollment
  useFetchEnrollmentUserQuery,
  useRegisterEnrollmentUserMutation,
  // questionnaire
  useGetQuestionsQuery,
  usePostQuestionsMutation,
  // saas
  useFetchSassTokenMutation,
} = api

export default api
