const getSaaSEndpoints = builder => ({
  fetchSassToken: builder.mutation({
    query: body => ({
      url: '/saas/token',
      method: 'POST',
      body,
    }),
  }),
})

export default getSaaSEndpoints
