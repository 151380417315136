export const CLOUDFLARE_IMAGE_IDS = {
  EMPTY_REFERRAL: '78775620-f18d-48bd-cf40-b009f7a92b00',
  HAPPY_STELLARFI_MEMBER: '5e5707a1-2a68-4fe7-b059-ab5db23ab700',
  ASTRONAUT_MEDITATING: 'b731fb34-0744-4d3f-b7b3-b7f6f898bd00',
  LOTS_OF_REVIEWS: '919ba9dc-6587-4264-882f-f964ee9b3500',
  HUMAN_IN_SPACE: '9e6a1689-05c4-491b-2e91-2e5171e6d100',
  ACTIVATE: 'a991693c-1ba9-4455-562a-d3c9d5698f00',
  LOGO_FULL: '17b9cdd0-dca9-40cd-b6ea-5e262900fb00',
  LOGO: '60d2e24b-6e4f-484e-af5b-dbbf5050b000',
  STELLAR_LOGO_HORIZONTAL_GREY: '2c2b2be4-11aa-42f5-b698-f23f4847dc00',
  STELLAR_LOGO_BLACK_BACKGROUND_WITH_PADDING: 'b5e96fb9-751c-430c-b66a-e9acb4e8e100',
  STELLAR_LOGO_BLACK_BACKGROUND: '8425b1d3-4e07-4939-bd3d-972193ad4800',
  STELLAR_LOGO_HORIZONTAL: 'c296b073-aa43-498c-29ac-8ba8db28bd00',
  SWEEPSTAKES: '976de7dc-bfac-43c7-b9dc-adfa548ae600',
  GREEN_MAN_IN_SPACE: '16ad7413-dfdb-4d1e-8745-3a6fcf61ef00',
  SCORE_PROJECTION_SECTION: '2d225659-acec-49db-1040-cb20d3cf5f00',
  LINKED_BILLS_SECTION: '2dbefcd2-c588-4fe2-6d03-578ba01c0c00',
  APP_IN_HANDS: '06d5bc15-de7e-4ef3-adc0-554accef6900',
  SHARE_ICON_SCREENSHOT: 'b04d015c-e440-4616-e388-1c9ee55dc500',
  ADD_APP_SCREENSHOT: '2e52fd75-1491-42a6-f062-3e71bdfc3900',
  ASTRONAUT_CHILLING: 'c5fdfbca-53b8-4c24-2464-47730196f900',
  VICTORY_ASTRONAUT: 'd69260cc-2ab6-48b0-9dda-a118a01cb500',
  ON_TIME_PAYMENTS: 'ff356660-de5d-4122-b0c2-2a73dc92ee00',
  REWARDS_REDEEM: '64fa4dd6-b254-49d0-334b-e5ee5ac16e00',
  REWARDS_ALL_BADGES: '7501ff1d-9cea-4e56-e90c-f11cdfa20000',
  CREDIT_SCORE_CARD: '67340a41-462d-4177-b9dc-96ea3caaf300',
  REWARDS_BADGES: '17102a78-82a1-4826-c301-3e995558e000',
  REWARDS_HISTORY_EMPTY: '515312fe-9dfa-4a20-49aa-a5f77f8add00',
  REWARDS_HISTORY_EMPTY_V1: '6a69e0c5-8074-41fc-9d7d-e6f8ee6da900',
  ADD_TO_HOME_SCREEN_SCREENSHOT: '0b95d08e-92f5-471d-8e3e-30c26b2fe000',
  TRANS_UNION_LOGO: '14223955-f1e9-455e-a6a0-1feb6777eb00',
  REWARDS_SUCCESS: 'da9b98b8-c372-4983-deff-278301851c00',
  COUNSELING: 'e0ef0dbb-1f51-455d-736b-cb7e6015bc00',
  VERIZON_LOGO: '029fa644-5d13-4021-18e1-71e2b01b0f00',
  T_MOBILE_LOGO: '52ca6941-c760-4954-89b3-0f9ee918c000',
  TWITCH_LOGO: 'd47b0097-27c0-43d8-55c2-b778f685a200',
  SHELL_LOGO: '23f21606-4b81-41ab-ad85-19d538d3fd00',
  ROCKET_EMOJI: '1331dd93-eac9-4ac3-366f-c77bbcc17c00',
  PAID_AT_T_BILL: '2871a4fe-ef88-4655-cfee-1461049bdf00',
  ROCKS: 'c856e80d-9360-44ef-d3dc-8b7a43ff2300',
  SPOTIFY_LOGO: 'bee79ba3-d111-4f96-6655-6a83480d5100',
  ACTIVATE_EXPERIMENTAL: '12797624-0e53-4581-8fa6-9978497aa500',
  INNOVIS_LOGO: '67b953d5-cd46-4c2f-922a-7361acd3c500',
  DOG_IN_HELMET: 'b39dafe5-da1f-422b-c326-8674cfdc4500',
  EXPERIAN_LOGO: '3f6d637e-b251-4864-3755-199fa8ff6000',
  PAYPAL_LOGO: 'db6abfa5-a359-4a09-9b10-3d988a20de00',
  PRIME_VIDEO_LOGO: '7c43e33f-ca7b-460a-6816-ca1e18e73800',
  LINKED_T_MOBILE_BILL: 'a718c130-ac90-454d-bb59-13f049e12400',
  NETFLIX_LOGO: '10a5eb10-45d5-46da-0c01-780cc74c3b00',
  EQUIFAX_LOGO: '4fdf5a3b-328f-4f07-53df-c0d391547b00',
  MASTERCARD_LOGO: '55722bdd-a0c4-457e-5f6c-8b0496338900',
  VISA_LOGO: 'd890d59e-5a98-459b-22ff-62fdc76ab800',
  VISA_LOGO_WHITE: 'e6e32626-579b-4c8d-78b6-eb7e6bcac300',
  VISA_LOGO_WITH_CHECKMARK: '2263361e-65cf-4782-3762-d06e17cb0400',
  VISA_VIRTUAL_NAME: 'c6081246-eee5-4aac-aa3a-5d793a04fb00',
  LINKED_BILLS_EMPTY_STATE: '50c3a28a-1d9b-44d1-f26e-7052f48fed00',
  GOALS_EMPTY_STATE: '4a2da542-539c-430d-b845-71f6d6e11b00',
  GOALS_EMPTY_STATE_BLUE: '06a3312b-e69d-4555-7f86-0cc3165f3800',
  EMPTY_GOALS: 'd29d1a5c-fe05-42ba-08e9-3a8c08afed00',
  EMPTY_GOALS_MOBILE: '3e671710-b2a5-4a4f-06d2-5233d0c44600',
  HULU_LOGO: '9fefc2a1-1935-4e63-c391-11df59724300',
  FLAMES_EMOJI: 'a9935435-660d-4591-314c-c2743f57c700',
  GOAL_2: 'a5fd1d68-07e5-4aa5-48e7-e63ba9354700',
  EMPTY_SCORE: 'e83f2587-3c38-4dc9-33a6-aaa315d7fb00',
  CHIME_LOGO: 'd2bee0d2-8b5e-4429-2bfe-5328d2001900',
  EMPTY_BILLS: '3282336a-85c5-469d-7e57-75f2e267d500',
  EMPTY_BILLS_MOBILE: 'ad85ee26-a1f4-4748-564e-c0fe16cb1600',
  EMPTY_PAYMENTS: '789c8162-9908-4345-16d7-8eb9721f0700',
  DISNEY_LOGO: 'c6ceaf26-e47e-4d52-158c-02ea7648a200',
  CREDIT_SCORE_AND_PAID_BILLS: 'cb9fdd9a-8480-4c04-7628-3dde74b6e900',
  CREDIT_SCORE_NO_TEXT: 'b43c0841-6991-418c-225e-c1d035c06b00',
  NO_PAYMENTS: '7400a3f4-cd91-4c3b-a70a-730f41d33900',
  OPENSKY_OFFER_LOGO: '2f556b4c-6327-44fa-14b4-57139366b900',
  UPSIDE_OFFER_LOGO: '646e69be-b332-4167-5aa7-ff1e69f97e00',
  MARBLE_OFFER_LOGO: '96f86671-fc0d-4c7b-f711-a49b34726300',
  ACCORNS_OFFER_LOGO: '5cb14ed7-6534-4248-9448-6ee607c71a00',
  DOVLY_OFFER_LOGO: 'd7cc484a-23f2-4d09-68d5-053440e44400',
  KLOVER_LOGO: '01af3f6d-dc41-4626-496b-0e4fa5877700',
  ACCOUNT_PAUSED_BANK_CARD: 'e8708bb9-19d8-4a61-9f9c-7d4811f6ff00',
  EMPTY_SCORE_PROJECTION: 'a2c1408b-d470-4b46-51e0-b59d8bf78000',
  STELLAR_LOGO_HORIZONTAL_WHITE: '17b9cdd0-dca9-40cd-b6ea-5e262900fb00',
  LITHIC_TO_CONNEXPAY_CARDS: '4f24bd44-ad1f-492e-4a63-b560b5d0e500',
  BILL_PAY_CARD_WITH_WARNING: 'afc06c11-e32b-4266-8e47-b7cfdb120b00',
  BILL_PAY_CARD_CONNEXPAY: '78818a7b-c194-4a93-3998-fb8b2eb7db00',
  NOVEMBER_2023_GIVEAWAY: '64997d83-75ac-46dd-0674-05cebe53c800',
  PRODUCT_ONBOARD_TOUR_DESKTOP: 'bed17093-659d-49b2-5a2a-38eff8e30500',
  PRODUCT_ONBOARD_TOUR_MOBILE: '6d45ba79-7402-465b-df3c-92a33b0f2f00',
  MOBILE_PHONE_WITH_DASHBOARD_SCREEN_AND_BILLS: '86e27742-62b6-4eaa-2643-4b4a0c9b0b00',
  CONNECT_BANK_PHONE: 'e7e9d11c-04b1-47bb-8f4e-6714e9ffce00',
  CREDIT_REPORT: '42e0336a-268a-43fd-c6af-b0f317046800',
  JANUARY_2024_GIVEAWAY: '39a15250-7242-40a9-093f-28cc4c8eb800',
  GLOW_GRADIENT: '3e853491-cbb0-47e9-6d4d-2a948aeac800',
  LINK_FIRST_BILL_EDUCATION: '7ce4d4db-7e01-4863-3ede-066288af5100',
  GOOGLE_PLAY_BUTTON: '1a966189-9a49-4b58-518f-381ac7f2f500',
  PRIME_GUARANTEE: '57b2fda9-7a0c-4dda-94e9-ee9150d98400',
  PRIME_GUARANTEE_CREDIT_SCORE_INCREASED: '492438b8-20a2-4423-ca1f-39b8d365b100',
  LINK_BANK_ACCOUNT_LOGO: '1f44ecce-d67e-4114-3d18-9b0f66a2da00',
  LINK_BANK_ACCOUNT_PHONE: 'a5db69af-1c07-44e7-27c9-524da8583500',
  APP_STORE_BUTTON: 'e0b0af80-f07f-486d-1aa2-2cf4e23d1d00',
  QUESTION: '5ddeb378-0f30-4ded-2fc9-adc18b5cc700',
  MAGNIFYING_GLASS_WITH_EYE: 'cc6f11b5-06af-4da2-c766-d634b39e9c00',
  CONNEXPAY_TO_HIGHNOTE_CARD: 'e30bfad6-5754-4c20-b211-6cd7c1481b00',
  HIGHNOTE_CARD_ACCENTS_TOP: 'd7e98540-0185-4428-146b-c39f1fef6e00',
  HIGHNOTE_STELLARFI_CARD: '47659a12-40ac-429a-d7b4-1f5c4c2ffd00',
  HIGHNOTE_STELLARFI_EMPTY_CARD: 'b552344e-d626-473d-01cf-4ce05a7c2300',
  HOURGLASS_WITH_LOGO: '327d6004-c7f3-42fb-2438-8d08785ed100',
  HIGHNOTE_CARD: '4ffdbb87-972b-491d-8282-006044571200',
  CARD_WITH_LOCK: '1b292ff1-86ab-4fba-b1bb-11e265215c00',
  HANDS_WITH_PHONE: '75fe0075-6917-4bcf-950e-bdacd4065600',
  DOUBLE_CARDS: '5617e957-f972-470e-0063-43c5f3529c00',
  ACTIVATE_CARD_WITH_PHONE: 'e530e267-d4d2-4b56-9195-d081de588c00',
  CLOCK: '08dd1318-4088-42f0-1752-1c4f5face500',
  BLUE_ROCKET: '8f0238c5-adb7-4414-c835-cca066638100',
  STELLAR_VECTOR_LOGO_RED: '09eb2601-7236-46c0-7f8e-e8b898d8c300',
  STELLAR_VECTOR_LOGO_BLACK: 'c077e74e-a9bb-460a-ca74-51dc794acb00',
  EMPTY_MANAGE_BILLS: 'f960ffa2-1217-4278-12ac-0050dacd5d00',
  HIGHNOTE_EMPTY_BILL_PAY_CARD: 'd4ea0899-f443-4096-6470-ad8d2ae88a00',
  ACORNS_MINI_LOGO: 'c10cac13-c0da-48f0-85df-cea16ff53e00',
  COPPER_MINI_LOGO: 'bc4cd212-f3cf-4597-b7dc-4c8c02fe6f00',
  KLOVER_MINI_LOGO: '17559b18-551d-469c-f440-9c5d2b02c700',
  UPSIDE_MINI_LOGO: 'b74e739c-bb6f-4348-cdbc-516386049900',
  BRIDGE_MONEY_MINI_LOGO: '27ae119e-c489-4739-720a-e602125c7e00',
  SAVVY_MINI_LOGO: '7cdae31b-8e44-4b2c-3a2a-d7259ef05500',
  CHIME_MINI_LOGO: '434f6d33-9eed-4ab8-de7e-43344d9d4f00',
  DOVLY_MINI_LOGO: '0e4a028e-7357-4754-23e0-8eb4ab0cff00',
  STUDIO_MONITOR: '32f3c3f4-e37b-4f63-9434-e4d220afed00',
  GET_MONEY: '95a073c8-ac1d-468b-e8f5-8bed35ddfc00',
  SAVE_MONEY: 'efa96660-6bc1-4719-e2fd-79bbbe6c6200',
  BUILD_CREDIT: '7acd516a-ed35-4713-6c5c-e3d050d75900',
}
